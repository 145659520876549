<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ $t("contactsModal.contacts") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`confirmModal.close`)"
            v-on:click="$emit('update:show', false)"
          ></button>
        </div>
        <div class="modal-body">
          <DynamicTable
            v-model:rows="rows"
            v-model:items="items"
            v-model:select-all="selectAll"
            v-on:update:val="editItems"
            :show-pin="false"
            :show-order="false"
            :show-select="false"
            type="contacts"
            :key="tableKey"
          />
        </div>
        <div class="modal-footer custom-input">
          <button class="btn btn-outline-primary" v-on:click="showAdd = true">
            {{ $t("companies.addNew") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <AddNewContactsModal
    v-model:show="showAdd"
    :partner_id="id"
    :type="type"
    v-on:add="addNew"
  />
</template>

<script>
import http from "../../modules/http";
import ws from "../../modules/ws";
import DynamicTable from "../lists/DynamicTable";
import AddNewContactsModal from "./AddNewContactsModal";
const bootstrap = require("bootstrap");

export default {
  name: "ContactsModal",
  props: {
    modelValue: Object,
    show: Boolean,
    id: Number,
  },
  emits: ["update:modelValue", "update:show"],
  data: function () {
    return {
      type: "partners",
      selectAll: false,
      showAdd: false,
      rows: [
        {
          name: "Name",
          key: "name",
          type: "string",
          show: true,
        },
        {
          name: "Phone",
          key: "phone",
          type: "string",
          show: true,
        },
        {
          name: "Email",
          key: "email",
          type: "string",
          show: true,
        },
        {
          name: "Skype",
          key: "skype",
          type: "string",
          show: true,
        },
        {
          name: "Default",
          key: "default",
          type: "boolean",
          show: true,
        },
      ],
      items: this.modelValue,
      myModal: null,
      tableKey: 1,
    };
  },
  mounted() {
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      this.myModal.show();
    } else {
      this.myModal.hide();
    }

    ws.init();

    ws.subscribe("modify", "partnerContact", (e) => {
      let obj = this.items[this.items.findIndex((o) => o.id === e.objectId)];
      if (obj !== undefined) {
        if (
          e.session !== this.sessionId ||
          e.rowKey === "email" ||
          e.rowKey === "phone" ||
          e.rowKey === "skype"
        ) {
          obj[e.rowKey] = e.value;
        }
      }
    });
  },
  watch: {
    show() {
      if (this.show) {
        this.myModal.show();
      } else {
        this.myModal.hide();
      }
    },
  },
  methods: {
    addNew() {
     // this.items.unshift(row);
      this.tableKey++;
    },
    editItems(val, row, id, item) {
      if (row === "default") {
        if (!val || val === 0) {
          let obj = this.items[this.items.findIndex((o) => o.id === id)];
          if (obj !== undefined) {
            obj.default = true;
          }
          return;
        } else {
          for (const i in this.items) {
            if (this.items[i].id !== id) {
              this.items[i].default = false;
            }
          }
        }
      }

      let data = {};
      data[row] = val;

      http.fetch("/crm/" + this.type + "/contact/" + item.id, data, true);
    },
  },
  components: { AddNewContactsModal, DynamicTable },
};
</script>
