<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ $t("addNewContactsModal.addNewContact") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
            v-on:click="$emit('update:show', false)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-start custom-input whitebg-input m-2">
            <div class="row">
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray"> {{ $t("addNewContactsModal.name2") }} </label>
                <input class="form-control" v-model="newContact.name" />
              </div>
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray"> {{ $t("finance.email") }} </label>
                <input class="form-control" v-model="newContact.email" />
              </div>
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray"> {{ $t("addNewContactsModal.phone") }} </label>
                <input class="form-control" v-model="newContact.phone" />
              </div>
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray"> {{ $t("addNewContactsModal.skype") }} </label>
                <input class="form-control" v-model="newContact.skype" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer custom-input">
          <button class="btn btn-primary" v-on:click="addNewContact">
            {{ $t("addNewContactsModal.addContact") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/modules/http";

const bootstrap = require("bootstrap");

export default {
  name: "AddNewContactsModal",
  props: {
    show: Boolean,
    partner_id: Number,
    type: String,
  },
  emits: ["update:show", "add"],
  data() {
    return {
      success: false,
      newContact: {},
      myModal: null,
    };
  },
  mounted() {
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      this.myModal.show();
    } else {
      this.myModal.hide();
    }
  },
  watch: {
    show() {
      if (this.show) {
        this.myModal.show();
      } else {
        this.myModal.hide();
      }
    },
  },
  methods: {
    addNewContact() {
      http
        .fetch(
          "/crm/"+this.type+"/" + this.partner_id + "/contact",
          this.newContact,
          true
        )
        .then((data) => {
          this.$emit("add", data);
          this.$emit("update:show", false);
          this.myModal.hide();
        });
    },
  },
  components: {},
};
</script>
