<template>
  <span class="regular-12 text-black text-center">
    <button class="btn btn-transparent px-1" v-on:click="showContacts = true">
      <BaseIcon name="book" />
    </button>
  </span>
  <contacts-modal v-model:show="showContacts" v-model="value" :id="id" />
</template>

<script>
import BaseIcon from "../../icons/BaseIcon";
import ContactsModal from "../../modals/ContactsModal";
export default {
  name: "Contacts",
  components: { ContactsModal, BaseIcon },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      showContacts: false,
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
